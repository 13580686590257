import Header from './Common'

import './Schedule.css'

import BallsSep from "./schedule-sep-balls.svg"
import FlowersSep from "./schedule-flower-sep.svg"

const ScheduleSection = () => (
    <div className="Intro ScheduleSection">
        <div className="ScheduleSectionInner">
            <h2>Wedding Schedule</h2>
            <p>31.7.2021</p>
            <img src={BallsSep} alt="separator" />

            <table>
                <tr>
                    <td className="schedule-time">14:30</td>
                    <td className="schedule-activity">
                        Gathering at the wedding ceremony place<br />
                        Kokoontuminen hääseremoniapaikalle
                    </td>
                </tr>
                <tr>
                    <td className="schedule-time">15:00</td>
                    <td className="schedule-activity">
                        Wedding ceremony<br/>
                        Hääseremonia
                    </td>
                </tr>
                <tr>
                    <td className="schedule-time">
                        15:20
                    </td>
                    <td className="schedule-activity">
                        Welcome toast<br/>
                        Tervetulomaljat
                    </td>
                </tr>
                <tr>
                    <td className="schedule-time">
                        15:30
                    </td>
                    <td className="schedule-activity">
                        Picture time with friends and family<br/>
                        Valokuvaus perheen ja ystävien kanssa
                    </td>
                </tr>

                <tr>
                    <td className="schedule-time">
                        16:50
                    </td>
                    <td className="schedule-activity">
                        Gathering to the main building<br/>
                        Kokoontuminen päärakennukselle
                    </td>
                </tr>
                <tr>
                    <td className="schedule-time">
                        17:00
                    </td>
                    <td className="schedule-activity">
                        Welcome words from Calliola &amp; starters<br/>
                        Tervetulosanat Calliolan väeltä &amp; alkuruoka
                    </td>
                </tr>
                <tr>
                    <td className="schedule-time">
                        18:20
                    </td>
                    <td className="schedule-activity">
                        Program #1<br/>
                        Ohjelma #1
                    </td>
                </tr>

                <tr>
                    <td className="schedule-time">
                        18:30
                    </td>
                    <td className="schedule-activity">
                        Main course<br/>
                        Pääruoka
                    </td>
                </tr>

                <tr>
                    <td className="schedule-time">
                        19:25
                    </td>
                    <td className="schedule-activity">
                        Program #2<br/>
                        Ohjelma #2
                    </td>
                </tr>
                <tr>
                    <td className="schedule-time">
                        20:00
                    </td>
                    <td className="schedule-activity">
                        Cake &amp; coffee<br/>
                        Kakku &amp; kahvi
                    </td>
                </tr>
                <tr>
                    <td className="schedule-time">
                        20:50
                    </td>
                    <td className="schedule-activity">
                        Wedding dance<br/>
                        Häävalssi
                    </td>
                </tr>
                <tr>
                    <td className="schedule-time">
                        21:05
                    </td>
                    <td className="schedule-activity">
                        Live music<br/>
                        Live musiikki
                    </td>
                </tr>
                <tr>
                    <td className="schedule-time">
                        22:00
                    </td>
                    <td className="schedule-activity">
                        Evening snack<br/>
                        Iltapala
                    </td>
                </tr>
                <tr>
                    <td className="schedule-time">
                        22:15
                    </td>
                    <td className="schedule-activity">
                        More live music<br/>
                        Livemusiikki jatkuu
                    </td>
                </tr>
                <tr>
                    <td className="schedule-time">
                        22:45
                    </td>
                    <td className="schedule-activity">
                        Afterparty<br/>
                        Bileet
                    </td>
                </tr>
                <tr>
                    <td className="schedule-time">
                        1:30
                    </td>
                    <td className="schedule-activity">
                        Good night!
                        Hyvää yötä!
                    </td>
                </tr>

            </table>
            <img src={FlowersSep} alt="separator" />
        </div>
    </div>
);

const Schedule = () => {
  return (
    <div>
      <Header />
      <ScheduleSection />
    </div>
  );
};

export default Schedule;
