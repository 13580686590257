import { slide as Menu } from 'react-burger-menu'

import './burger-menu.css';

// Images
import HeaderIcon from "./header-icon.png"
import RoseSeparator from "./flower.svg"
import FooterImg from "./footer.png"

const Header = () => {
    return(
      <header className="Header">
        <div id="header-title">
          <img id="header-icon" src={HeaderIcon} alt="header icon" />
          <p>Mirkka & Ozz wedding invitation</p>
        </div>
        <div id="header-menu">
          <Menu right  >
            <a id="home" className="menu-item" href="/">Home</a>
            <a id="schedule" className="menu-item" href="/Schedule">Schedule</a>
            <a id="venue" className="menu-item" href="/Venue">Venue</a>
            <a id="accommodation" className="menu-item" href="/Accommodation">Accommodation</a>
            <a id="invitation" className="menu-item" href="/Invitation">Invitation</a>
          </Menu>
        </div>
      </header>
    );
}

export const Separator = () => (
  <div className="Separator">
    <img src={RoseSeparator} alt="Rose separator" />
    <img src={RoseSeparator} alt="Rose separator" />
    <img src={RoseSeparator} alt="Rose separator" />
  </div>
);

export const Footer = () => (
  <div className="Footer">
    <img src={FooterImg} alt="footer" />
  </div>
);

export default Header;