import Header, {Separator, Footer} from "./Common"

// CSS
import './Accommodation.css'

// Images
import BLeft from './Balls left.svg'
import BRight from './Balls right.svg'
import FlowersSep from "./schedule-flower-sep.svg"

const AccommodationSection = () => (
  <div className="Intro AccommodationSection">
    <div className="AccommodationInner">
      <div className="AccommodationTitle">
        <img className="AccTitleBalls" src={BLeft} />
        <h2>Accommodation</h2>
        <img className="AccTitleBalls" src={BRight} />
      </div>
      <p>31st of July - 1st of August</p>
      <p><b>Calliolla offers brilliant opportunity to stay over the night in the beautiful archipelago area. </b></p>
      <img src={FlowersSep} alt="separator" />
    </div>
  </div>
);

const AccommodationDetails = () => (
  <div className="AccomodationDetails">
    <h2>How does it work?</h2>
    <p>
      The accommodation options are comfortable villas for 1-8 people and
      apartments for 2-4 people.
    </p>
      
    <p>
      If you're planning to stay over the night, we can plan with you
      what type of accomodation you'd prefer. So, don't try to book
      it directly from Calliola, we'll do that for you.
    </p>

    <p>
      Remember the place has A-licence to sell alcohol so you are not
      allowed to bring any alcoholic drinks to Calliola.
    </p>
    <h2>Accommodation options and prices</h2>

    <p><b>Place for 1 person:</b> 120€ total</p>

    <p><b>Place for 2 people</b> 180€ total</p>

    <p><b>Place for 4 people:</b> 340€ total</p>

    <p><b>Place for 5 people:</b> 80€/person (Downstairs livingroom for 2 people and upstairs for 3 people)</p>

    <p><b>Place for 6 people:</b> 80€/person (Upstairs for 1x2 people and upstairs for 4 people or 3x1person room and upstairs for 3 people)</p>

    <p><b>Place for 8 people:</b> 80€/person (2x2 people room and upstairs for 4 people)</p>


    <p>The price includes ALV 10%, accommodation, bed sheets, towels and breakfast.</p>

    <p>More information from the <a href="https://calliola.com/en/accommodation/">Calliola website</a></p>

    <h2>Other options</h2>

    <p>
      If the accommodation provided in Calliola is not suitable for you. There is the option of booking hotels
      in Karjaa or Tammisaari.
    </p>
  </div>
);

const Accommodation = () => {
  return (
    <div>
      <Header />
      <AccommodationSection />
      <Separator />
      <AccommodationDetails />
      <Footer />
    </div>
  );
};

export default Accommodation;