// local imports
import Header, {Footer, Separator} from "./Common"

// Images
import EllipseSeparator from "./ellipse-separator.svg"
import BLeft from './Balls left.svg'
import BRight from './Balls right.svg'

// CSS
import './Venue.css'

const VenueSection = () => (
  <div className="Intro">
    <div className="VenueSection">
      <div className="VenueSectionImage"></div>
      <div className="VenueSectionIntro">
        <div className="VenueTitle">
          <img className="VenueTitleBalls" src={BLeft} />
          <h2>Venue</h2>
          <img className="VenueTitleBalls" src={BRight} />
        </div>
        <p>Our wedding venue is in Calliola which is located to the beautiful archipelago of Snappertuna. The place is an old farm
        building and it’s surrounded by gorgeous nature where deers walk on the backyard and many types of birds are flying
        around. We would like to not invite you not only for our the wedding but also to spend
        a nice weekend with us in the beautiful archipelago.</p>
        <img id="elipse-separator" src={EllipseSeparator} alt="RSVP separator" />
      </div>
    </div>
  </div>
);

const GoodToKnow = () => (
  <div className="WeddingInfoSection GoodToKnow">
    <h2>Good to know</h2>
    <p><b>Calliola is an old farm house yard in the middle of beautiful archipelago area. This means the landscape is gorgeous but also that the nature is very present.</b></p>
    <ul>
      <li>The ground is either sand or grass on the yard,  remember this when you choose your shoes, dress or suit.</li>
      <li>There might be mosquitos teasing us. So some spray to avoid them or cortisol to treat the itchy areas after the bites would be useful.</li>
      <li>Where the bird, there the bird poop as well. The ceremony areas will be cleaned as well as possible but it’s good to be careful where to step.</li>
      <li>There are beautiful beaches around there area - if you would like to swim before or after the wedding  bring your swimming suits.</li>
    </ul>
  </div>
);

const Map = () => (
  <div className="WeddingInfoSection">
    <h2>Map</h2>
  </div>
);

const Venues = () => {
  return (
    <div>
      <Header />
      <VenueSection />
      <GoodToKnow />
      <Separator />
      <Footer />
    </div>
  );
};

export default Venues;