import Header, {Footer, Separator} from './Common'

import './Invitation.css'

import PlantSeparator from "./plantseparator.svg"

const InvitationSection = () => (
  <div className="InvitationSection">
   <div className="InvitationImage" />
   <div className="InvitationContent">
     <h2>Wedding Invitation</h2>
      <div className="PlantSeparator">
        <img id="plantsep" src={PlantSeparator} alt="plant separator" />
      </div>
     <h3>&ndash; Mirkka &amp; Ozz &ndash;</h3>

     <hr/>

      <p>
        We really hope to see you in our wedding!
        <br/>
        Please, reply to us by whatsapp or email to the
        following questions:
     </p>

     <p>
       1. Are you able to join?
     </p>

     <p>
       2. Do you have any food allergies?
     </p>

     <p>
       3. Would you like to stay over the night? 
     </p>

     <p>
       4. Would you like to have a lunch (15e) at the wedding place at 12:00 on Saturday? 
     </p>

     <p>
       5. Would you like to have a late evening snack (10e) at the wedding place at 11pm?
     </p>

     <p id="hearticon"> &hearts; </p>

     <p>If you got this, you know how to contact us.</p>
   </div>
  </div>
);

const Invitation = () => {
  return (
    <div>
      <Header />
      <InvitationSection />
      <Separator />
      <Footer />
    </div>
  );
};

export default Invitation;