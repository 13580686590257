import React from 'react';
import Amplify from "aws-amplify";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

// amplify exports
import awsExports from "./aws-exports";

// local imports
import Header, {Footer, Separator} from "./Common"
import Venue from "./Venue";
import Schedule from "./Schedule";
import Accommodation from "./Accommodation";
import Invitation from "./Invitation";

// Images
import RSVPSeparator from "./rsvp-separator.svg"
import ScheduleIcon from "./Schedule.svg"
import VenueIcon from "./Venue.svg"
import AccommodationIcon from "./Accommodation.svg"
import MirkkaAndOzzEllipse from "./mirkka-and-ozz-ellipse.png"

// CSS 
import './WeddingFrontPage.css';
import './Common.css'

Amplify.configure(awsExports);


const CountdownSection = () => {
  return (
    <div className="Intro CountdownSection">
      <div className="Countdown">
        <h2>Welcome to our wedding!</h2>
        <h2 id="wedding-date">31st of July 2021</h2>
      </div>
    </div>
  )
}

const SectionPart = ({ title, content }) => ( 
  <Link to={`/${title}`}>
    <div className="SectionPart">
      <img src={content} alt={`${title} icon`} />
      <h3>{title}</h3>
    </div>
  </Link>
);


const Sections = () => {
  return(
    <div className="Sections">
      <SectionPart
        title="Schedule"
        content={ScheduleIcon} />
      <SectionPart
        title="Venue" 
        content={VenueIcon} />
      <SectionPart
        title="Accommodation"
        content={AccommodationIcon} />
    </div>
  )
}

const RSVP = () => (
  <div className="RSVP WeddingInfoSection">
    <div className="RSVPContent">
      <h2>Are you able to join?</h2>
      <img id="rsvp-separator" src={RSVPSeparator} alt="RSVP separator" />
      <div className="RSVPParagraphs">
        <p>We'd be delighted if you're able to join us!</p>
        <p>Any questions? Please contact us :)</p>
      </div>
      <div>
        <Link to='/Invitation'> 
          <button> Go to invitation </button>
        </Link>
      </div>
    </div>
  </div>
);

const OurMessage = () => (
  <div className="OurMessage WeddingInfoSection">
    <div className="OurMessageInner">
      <img src={MirkkaAndOzzEllipse} alt="mirkka and ozz" />
      <div className="OurMessageText">
        <div className="OurMessageTextInner">
          <p>“Hi there! Last year was the craziest. We really wish we all are able to meet this summer and get us two married.</p>
          <p>Let’s stay safe and healthy. Hopefully in that way we are able to make this event to happen.“</p>
          <div className="OurMessageTextSignature">
            <p>Hugs: Mirkka &amp; Ozz</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const SnappertunaBanner = () => (
  <div className="SnappertunaBanner WeddingInfoSection">
    <a href="https://calliola.com/en/front-page/">
    <div className="SnapBannerInner">
      <h2>Our wedding will be held on the beatiful Calliola, Snappertuna.</h2>
      <p>Visit the website</p>
    </div>
    </a>
  </div>
);


const WeddingFrontPage = (props) => (
  <div>
    <Header />
    <CountdownSection />
    <Sections />
    <Separator />
    <OurMessage />
    <SnappertunaBanner />
    <Separator />
    <RSVP />
    <Footer />
  </div>
)

const WeddingRoute = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <WeddingFrontPage />
        </Route>
        <Route path="/Accommodation">
          <Accommodation />
        </Route>
        <Route path="/Venue">
          <Venue />
        </Route>
        <Route path="/Schedule">
          <Schedule />
        </Route>
        <Route path="/Invitation">
          <Invitation />
        </Route>
      </Switch>
    </Router>
  );
}

export default WeddingRoute;